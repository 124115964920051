@import '@/app/styles/abstracts/shared-variables.scss';

.mainFooter {
  height: 232px;
  background: var(--footer-bg);

  @include media-breakpoint-down(lg) {
    height: 244px;
  }
}

.mainFooterDark {
  background: var(--footer-dark-bg);
}

.mainFooterRightColumn {
  text-align: right;
  @include media-breakpoint-down(lg) {
    text-align: left;
    margin-top: map-get($spacers, 3);
  }
}

.linksList {
  list-style: none;
  padding: 0;
  margin: 0;
}

